@import '~/src/app/utils/variables.module.scss';

@-moz-keyframes rotate {
  0% {
    -moz-transform: rotate(-8deg);
  }
  100% {
    -moz-transform: rotate(8deg);
  }
}
@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(-8deg);
  }
  100% {
    -webkit-transform: rotate(8deg);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate(-8deg);
    transform: rotate(-8deg);
  }
  100% {
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg);
  }
}

.case-container {
  padding-bottom: 110px;

  .fill-case-container {
    padding-bottom: 0;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    padding: 0 24px;

    .field {
      margin-bottom: 20px;
      position: relative;

      .form-group {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .chip-button-wrapper {
          position: relative;
          .toggle-chip.isNotClickable {
            pointer-events: none;
          }
          .remove-chip-button-wrapper {
            position: absolute;
            right: -6px;
            top: -16px;
            z-index: 1;
            .remove-chip-button {
              height: 34px;
              width: 40px;
              min-width: auto;
              padding: 0;
              .icon-wrapper {
                background-color: $primaryPurple;
                border-radius: 10px;
                height: 20px;
                width: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                pointer-events: none;
                .svg-icon {
                  height: 10px;
                  width: 10px;
                  -webkit-animation: rotate 0.2s linear infinite alternate;
                  -moz-animation: rotate 0.2s linear infinite alternate;
                  animation: rotate 0.2s linear infinite alternate;
                }
              }
            }
          }
        }
      }

      .last-updated-row {
        height: 22px;
        display: flex;
        align-items: center;
        color: $grey5;
        font-size: 12px;
        margin: 0 0 4px 6px;

        .avatar-container {
          margin-right: 6px;
        }
      }

      .MuiFormControl-root {
        width: 100%;
        &.text-field.MuiTextField-root {
          margin-bottom: 12px;
        }
      }

      .remove-field-button {
        height: 40px;
        width: 40px;
        background-color: $white;
        position: absolute;
        top: -10px;
        right: 0;
        z-index: 1;
        .MuiButton-startIcon {
          height: 16px;
          width: 16px;
          .svg-icon {
            height: 16px;
          }
        }
      }
    }

    .add-field-wrapper {
      border-bottom: 1px solid $grey2;
      position: relative;
      height: 20px;
      margin-bottom: 30px;
      .add-field-button {
        height: 40px;
        width: 40px;
        background-color: $white;
        position: absolute;
        top: 0;
        right: 0;
        .MuiButton-startIcon {
          height: 16px;
          width: 16px;
          .svg-icon {
            height: 16px;
          }
        }
      }
    }
  }

  // Form labels
  .form-group,
  .form-dynamic-select,
  .text-field-container {
    .form-group-label,
    .form-dynamic-select-label,
    .text-field-label {
      flex-basis: 100%;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 12px;

      .label-container {
        width: fit-content;
        position: relative;
        width: calc(100% - 30px);
      }

      &.needsAttention .label-container::after {
        content: '';
        position: absolute;
        height: 10px;
        width: 10px;
        border-radius: 10px;
        background-color: $primaryPurple;
        right: -14px;
        top: 0;
      }
    }
  }

  .edit-template-header {
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 90px;
    padding: 0 24px;
    box-sizing: border-box;
    background-color: $white;
    z-index: 2;
    border-bottom: 1px solid $grey2;

    .close-button {
      .svg-icon {
        height: auto;
        path {
          stroke: $grey6;
        }
      }
    }

    .edit-template-title {
      font-size: 24px;
      font-weight: 600;
      width: 100%;
      text-align: center;
      padding-right: 24px;
    }
  }

  .procedure-select {
    margin-bottom: 18px;
  }

  .huddle-template {
    background-color: $grey1;
    margin-bottom: 18px;
    border: 1px solid $grey2;
    border-radius: 12px;
    padding: 12px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: 16px;
      text-align: center;
      margin-bottom: 10px;
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .use-button {
        padding: 0 26px;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        height: 44px;
        flex: 1;
        margin-right: 10px;

        &.use .MuiButton-startIcon {
          height: 20px;
          width: 20px;
        }
      }

      .edit-button {
        background: $white;

        .MuiButton-startIcon {
          height: 20px;
          width: 20px;
        }
      }
    }
  }

  .case-description {
    margin-bottom: 18px;
  }

  &.isWithBanner {
    padding-bottom: 140px;
    .MuiButton-root.btn.fab {
      margin-bottom: 30px;
    }
  }
}
