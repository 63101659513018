@import '~/src/app/utils/variables.module.scss';

.follower-chip {
  background-color: $white;
  margin: 0 8px 10px 0;
  height: 34px;
  line-height: 34px;
  font-weight: 500;
  padding: 0 8px 0 3px;
  border: 1px solid $grey3;
  color: $black;
  border-radius: 17px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: auto;
  position: relative;

  .avatar-container {
    margin-right: 8px;
  }

  .MuiButtonBase-root.remove-button {
    position: absolute;
    top: -12px;
    right: -12px;
    height: 24px;
    width: 24px;
    padding: 0;
    background-color: transparent;
    .MuiButton-startIcon {
      height: 8px;
      width: 8px;
      background-color: $primaryPurple;
      border-radius: 50%;
      padding: 3px;
    }
  }

  &.seen {
    padding-right: 28px;

    .seen-icon-container {
      position: absolute;
      height: 18px;
      width: 18px;
      right: 6px;
      .seen-icon {
        right: 0;
      }
    }
    .seen-icon {
      position: absolute;
      height: 18px;
      right: 6px;
    }
  }

  &.small {
    height: 26px;
    padding: 0 8px 0 2px;
    font-size: 12px;
  }
}
