@import '~/src/app/utils/variables.module.scss';

.rich-text-field {
  width: 100%;
  border: 1px solid $grey3;
  border-radius: 7px;

  &:focus-within {
    border-color: $primaryPurple;
    .control-group {
      display: block;
    }
    p.is-empty::before {
      content: '';
    }
  }

  .control-group {
    border-bottom: 1px solid $grey3;
    padding: 6px 10px;
    display: none;
    .button-group {
      .button {
        background-color: $white;
        border-radius: 4px;
        height: 30px;
        width: 30px;
        min-width: 30px;
        margin-right: 10px;
        &.is-active {
          background-color: $grey4;
        }
        .button-icon {
          height: 20px;
          width: 20px;
        }
      }
    }
  }

  .editor-container {
    .editor {
      padding: 10px;
      outline: none;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      line-height: 18px;
      p {
        padding: 0;
        margin: 0;
      }
      ul,
      ol {
        margin-block-start: 0;
        margin-block-end: 0;
        padding-inline-start: 26px;
      }
      .mention {
        background-color: rgba($green, 0.5);
        border-radius: 4px;
        color: #285046;
        font-weight: 600;
        padding: 0 2px;
        white-space: nowrap;
      }
    }
  }

  &.read-only {
    border: none;
    border-radius: 0;
    width: auto;
    .editor-container {
      .editor {
        padding: 0;
        line-height: 20px;
      }
    }
  }

  // Placeholder
  p.is-empty::before {
    color: $grey5;
    content: attr(data-placeholder);
    pointer-events: none;
  }
}

.mention-list {
  background-color: $white;
  position: relative;
  bottom: 30px;
  transform: translateY(-100%);
  padding: 10px 0 10px;
  border: 1px solid $grey4;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto;
  button {
    background-color: transparent;
    color: $black;
    border: none;
    text-align: left;
    margin-bottom: 4px;
    padding: 8px 22px 8px 12px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    .avatar-container {
      margin-right: 8px;
    }
    &.is-selected {
      background-color: $grey3;
    }
  }
}
