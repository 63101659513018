@import '~/src/app/utils/variables.module.scss';

.case-list-item {
  background-color: $primaryYellow;
  border-radius: 16px;
  margin-bottom: 8px;
  padding: 12px 16px 12px 0;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: row;

  &:not(.with-handle) {
    padding-left: 18px;
  }

  .drag-handle {
    width: 18px;

    .drag-icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 34px;
      width: 100%;

      .svg-icon {
        height: auto;
      }
    }
  }

  .case-list-item-content {
    flex: 1;

    .header {
      display: flex;
      align-items: center;
      padding-right: 16px;
      flex-wrap: wrap;

      > div {
        margin-right: 8px;
        margin-bottom: 10px;
      }

      .attending {
        display: flex;
        align-items: center;
        background-color: $yellow3;
        height: 34px;
        padding: 0 12px 0 4px;
        border-radius: 34px;
        margin-right: 8px;

        .name {
          display: flex;
          font-weight: 600;
          margin-left: 8px;
          font-size: 14px;
          line-height: 14px;

          .same-user {
            color: $grey6;
            white-space: pre;
          }
        }
      }

      .case-info-chip {
        margin-left: 0;
      }

      .case-list-item-menu {
        position: absolute;
        right: 10px;
        top: 17px;
      }
    }

    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .patient-info {
      display: flex;
      font-size: 12px;
      font-weight: 600;
      color: $grey6;
      margin-top: 6px;

      .initials {
        margin-right: 4px;
      }
    }

    .middle {
      display: flex;
      align-items: center;
      margin: 12px 0;

      .intraop-indicator {
        background-color: $yellow3;
        height: 24px;
        width: fit-content;
        padding: 0 8px;
        display: flex;
        align-items: center;
        border-radius: 23px;
        margin-right: 8px;

        .svg-icon {
          height: 15px;
          margin-right: 5px;
        }

        .text {
          font-size: 12px;
          font-weight: 600;
          margin: 0;
        }
      }

      .viewers-group-container {
        display: flex;
        align-items: center;

        .eye-icon {
          height: 16px;
          width: 16px;
          margin-right: 4px;
        }
      }
    }

    .footer {
      display: flex;
      align-items: flex-end;

      .case-list-item-button {
        padding: 0 12px;
        margin-right: 10px;
        white-space: nowrap;
        flex-shrink: 0;

        .MuiButton-endIcon {
          height: 7px;
        }
      }

      .standard-proc-button {
        color: $primaryPurple;
        text-decoration: underline;
      }

      .case-status {
        margin-left: auto;
      }
    }
  }

  &.isGhost {
    z-index: 2;
    box-shadow: -2px 2px 30px 0px #00000014;
  }

  &.isDragging {
    border: none;

    .attention-badge {
      display: none;
    }

    &::before {
      content: '';
      height: 100%;
      width: 100%;
      background-color: $yellow2;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='peachPuff' stroke-width='2' stroke-dasharray='6%2c 6' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
      border-radius: 8px;
    }
  }

  .feedback-button {
    display: flex;
    margin-left: auto;
    padding-right: 0;
    height: auto;
    margin-top: 12px;

    .MuiRating-icon {
      width: 30px;

      .svg-icon {
        height: 20px;
      }
    }
  }

  .attention-badge {
    background-color: $primaryPurple;
    color: $white;
    font-size: 10px;
    line-height: 10px;
    font-weight: 700;
    position: absolute;
    right: -4px;
    top: -5px;
    height: 18px;
    width: 18px;
    border-radius: 18px;
    border: 1px solid $white;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    .svg-icon {
      height: 8px;
      width: 8px;
    }
  }

  .confetti {
    pointer-events: none;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    box-sizing: border-box;
    z-index: 1;
    @media (min-width: $mq-mobile) {
      justify-content: flex-end;
    }

    .lottie-container {
      height: 150%;
    }
  }

  .case-list-item-comments {
    background-color: $white;
    padding: 14px 10px 2px;
    border-radius: 12px;
    margin-top: 20px;

    .comment {
      &:last-of-type {
        margin-bottom: 0;
      }

      .comment-bubble {
        margin-bottom: 12px;

        .like-button {
          display: none;
        }
      }
    }

    .more-separator {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin: -12px 0 8px;

      &::before {
        content: '';
        position: absolute;
        height: 1px;
        background-color: $grey3;
        width: 100%;
      }
    }

    .action-button {
      background-color: $white;
      color: $primaryPurple;
      font-size: 12px;
      height: 20px;
      line-height: 20px;
      padding: 0 12px;
    }

    .actions {
      display: flex;
      margin-bottom: 8px;
      width: 100%;
      .more-button {
        margin-left: -8px;
      }
      .mark-as-read-button {
        margin-left: auto;
        background-color: $primaryPurple;
        color: $white;
      }
    }
  }
}
