@import '~/src/app/utils/variables.module.scss';

// procedure select ripple effect
@-webkit-keyframes ripple-effect-purple {
  0% {
    box-shadow: 0 4px 10px rgba($primaryPurple, 0.1), 0 0 0 0 rgba($primaryPurple, 0.1),
      0 0 0 5px rgba($primaryPurple, 0.1), 0 0 0 10px rgba($primaryPurple, 0.1);
  }
  100% {
    box-shadow: 0 4px 10px rgba($primaryPurple, 0.1), 0 0 0 5px rgba($primaryPurple, 0.1),
      0 0 0 10px rgba($primaryPurple, 0.1), 0 0 0 20px rgba($primaryPurple, 0);
  }
}

@keyframes ripple-effect-purple {
  0% {
    box-shadow: 0 4px 10px rgba($primaryPurple, 0.1), 0 0 0 0 rgba($primaryPurple, 0.1),
      0 0 0 5px rgba($primaryPurple, 0.1), 0 0 0 10px rgba($primaryPurple, 0.1);
  }
  100% {
    box-shadow: 0 4px 10px rgba($primaryPurple, 0.1), 0 0 0 5px rgba($primaryPurple, 0.1),
      0 0 0 10px rgba($primaryPurple, 0.1), 0 0 0 20px rgba($primaryPurple, 0);
  }
}

// ripple effect appear transition
@-webkit-keyframes ripple-effect-opacity {
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes ripple-effect-opacity {
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.huddle-content {
  .form-header {
    padding-top: 110px;
    padding: 110px 24px 0;
    .selects {
      padding-bottom: 8px;
      margin: 20px 0 18px;
      border-bottom: 1px solid $grey2;

      .selects-row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        > div {
          margin-bottom: 10px;
        }

        .case-info-chip {
          margin-left: 0;
          margin-right: 8px;
        }
      }
    }

    .title-container {
      display: flex;
      align-items: center;
      min-height: 36px;

      .edit-title-button {
        margin-left: 20px;
        flex-shrink: 0;
        height: 36px;
        width: 36px;
        background-color: transparent;

        .MuiButton-startIcon {
          height: 20px;
          width: 20px;
        }
      }

      .case-title {
        width: 100%;
        border: none;
        outline: none;
        max-width: calc(100% - 56px);

        &,
        .MuiInputBase-root {
          &,
          .MuiInputBase-input {
            &,
            &::placeholder {
              border: none;
              font-size: 16px;
              font-weight: 600;
              font-family: 'Inter', sans-serif;
              color: $black;
              opacity: 1;
            }

            &::placeholder {
              color: $grey5;
            }

            .Mui-disabled {
              color: $black;
              opacity: 1;
              -webkit-text-fill-color: $black;
            }
          }
        }
      }

      .case-list-item-menu {
        align-self: flex-start;
        margin-left: auto;
      }

      .expand-button {
        height: 36px;
        width: 36px;
        flex-shrink: 0;
        background-color: $grey2;

        .MuiButton-startIcon {
          height: 7px;
          width: 14px;
          display: flex;
          align-items: flex-start;

          .svg-icon {
            transition: transform 150ms ease-in-out;
            height: auto;
            width: auto;
          }
        }
      }

      .title-read-only-container {
        width: calc(100% - 56px);
        padding: 4px 0 5px;
        position: relative;

        .title-read-only {
          font-size: 20px;
          line-height: 24px;
          font-weight: 600;
          font-family: 'Inter', sans-serif;
          color: $black;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .edit-title-button {
          position: absolute;
          right: -56px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    &.expanded {
      .title-container {
        .case-title {
          .MuiInputBase-root {
            .MuiInputBase-input {
              letter-spacing: normal;
              font-size: 20px;
              line-height: 24px;
            }
          }
        }
      }

      .expand-button .MuiButton-startIcon .svg-icon {
        transform: rotate(180deg);
      }
    }

    .patient-info {
      display: flex;
      font-size: 12px;
      font-weight: 600;
      color: $grey6;

      .initials {
        margin-right: 4px;
      }
    }
  }
  .huddle-tabs {
    background-color: $white;
    position: fixed;
    top: 0;
    width: 100%;
    height: 110px;
    margin-left: -24px;
    z-index: 2;
    display: flex;
    align-items: center;
    padding: 32px 24px 38px 24px;
    box-sizing: border-box;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.14);
    transition: box-shadow 500ms ease-in-out;

    .flex-container {
      flex: 1;
      display: flex;

      &.right-align {
        justify-content: flex-end;
      }
    }

    .back-button .svg-icon {
      height: auto;
    }

    .tabs {
      background-color: $grey2;
      height: 44px;
      width: 120px;
      border-radius: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      position: relative;

      .tab {
        background-color: $white;
        border: none;
        height: 40px;
        min-width: 58px;
        width: 58px;
        position: relative;

        .MuiButton-startIcon {
          height: 16px;
          width: 16px;
          margin: 0;

          .icon-container {
            display: flex;
            align-items: center;
            justify-content: center;

            .svg-icon {
              height: 18px;
            }

            .tab-badge {
              background-color: $primaryPurple;
              color: $white;
              font-size: 10px;
              line-height: 10px;
              font-weight: 700;
              position: absolute;
              right: 0;
              top: -7px;
              height: 18px;
              width: 18px;
              border-radius: 18px;
              border: 1px solid $white;
              box-sizing: border-box;
              display: flex;
              justify-content: center;
              align-items: center;

              &.wide {
                width: 28px;
                right: -8px;
              }
            }
          }
        }

        &:not(.selected) {
          background-color: transparent;

          .MuiButton-startIcon .icon-container .tab-badge {
            border-color: $grey2;
          }
        }

        &.chat .MuiButton-startIcon {
          .svg-icon {
            height: 16px;
          }
        }
      }
    }

    &.withShadow {
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.14);
    }

    .save-indicator {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .svg-icon {
        height: 12px;
        margin-right: 6px;
      }

      .text {
        color: $grey6;

        &.saved {
          color: $grey6;
        }
      }
    }
  }
}

.fill-case-container {
  padding: 0 24px 110px;

  .case-status {
    width: fit-content;
    margin-bottom: 18px;
  }
}

.not-ready-case .fill-case-container {
  padding-bottom: 50px;
}

.fill-case-container,
.procedure-template-modal {
  .procedure-select {
    .select-field {
      margin-bottom: 10px;
    }

    .title {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 12px;
    }

    .proc-suggestions {
      display: flex;
      flex-direction: column;

      .suggestions {
        padding-left: 16px;
        display: flex;
        flex-direction: column;

        .suggestions-label {
          font-size: 12px;
          margin-bottom: 8px;
        }

        .suggestion-button-container {
          margin: 5px 0;
          max-width: 90%;

          .suggestion {
            text-align: left;
            padding: 0 18px;
            box-sizing: border-box;
            height: auto;
            background-color: $pink2;
            border: none;

            p {
              width: 100%;
              margin: 0.5em 0;
              color: $black;
            }
          }
        }
      }

      .search-procedure-button-container {
        position: relative;
        margin: 10px 0 20px;
        border-radius: 40px;

        &::before {
          content: '';
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          border-radius: inherit;
          opacity: 0;
          animation: ripple-effect-purple 0.9s linear infinite, ripple-effect-opacity 4s forwards;
          pointer-events: none;
        }

        .search-procedure-button {
          background-color: $grey2;
          height: 44px;
          width: 100%;
          padding: 0 2px 0 16px;
          color: $grey6;
          outline: 2px solid $primaryPurple;

          .content {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .icon-container {
              height: 40px;
              width: 40px;
              background-color: $white;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;

              .svg-icon {
                height: 15px;
              }
            }
          }
        }
      }
    }
  }
}
