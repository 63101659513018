@import '~/src/app/utils/variables.module.scss';

.modal-dialog {
  backdrop-filter: blur(4px) opacity(0);
  transition: backdrop-filter 0.3s;
  &.blur {
    backdrop-filter: blur(4px) opacity(1);
  }
  .MuiDialog-container {
    align-items: end;
    .MuiPaper-root {
      background-color: $white;
      margin: 0;
      width: 100vw;
      max-width: none;
      border-radius: 16px 16px 0 0;
    }
  }
  &.full-height {
    .MuiPaper-root {
      height: 100%;
      max-width: 100%;
      max-height: none;
      overflow-y: hidden;
      border-radius: 0;
      box-shadow: none;
    }
  }
  &.centered {
    .MuiDialog-container {
      align-items: center;
    }
    .MuiPaper-root {
      border-radius: 16px;
      width: auto;
    }
  }
  &.edit-template-modal .edit-template-content {
    padding-top: 110px;
    .procedure-select {
      margin: 0 24px 18px;
    }
    .field .last-updated-row {
      display: none;
    }
  }
  @media (min-width: $mq-mobile) {
    max-width: $mq-mobile;
    margin: 0 auto;
  }
  .huddle-banner {
    background-color: $grey2;
    border-top: 1px solid $grey3;
    padding: 12px 24px;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    z-index: 2;
    .svg-icon {
      height: 16px;
      margin-right: 8px;
    }
    .banner-text {
      font-size: 12px;
      line-height: 16px;
      color: $grey6;
    }
  }
}
