@import '~/src/app/utils/variables.module.scss';

.modal-container {
  .comments {
    padding: 0 24px 110px;
    display: flex;
    flex-direction: column;
    .comments-container {
      padding-top: 20px;
      .notifications-banner {
        display: none;
      }
      &.isShow {
        .notifications-banner {
          display: block;
        }
      }
      .comment-form {
        position: fixed;
        bottom: 0;
        width: 100%;
        margin-left: -24px;
        .notifications-banner {
          background-color: $grey2;
          padding: 6px 0;
          .notification {
            display: flex;
            align-items: center;
            padding: 12px 24px;
            max-height: 28px;
            box-sizing: border-box;
            .svg-icon {
              height: 16px;
              margin-right: 8px;
              flex-shrink: 0;
            }
            .notification-text {
              font-size: 12px;
              line-height: 16px;
              color: $grey6;
            }
          }
        }
        .input-container {
          display: flex;
          border-top: 1px solid $grey3;
          padding: 24px;
          background-color: $white;
          box-sizing: border-box;
          align-items: flex-end;
          .rich-text-field {
            margin-right: 8px;
          }
          .submit-message-button {
            height: 44px;
            width: 44px;
            padding: 0;
            min-width: auto;
            flex-shrink: 0;
            .svg-icon {
              height: auto;
            }
          }
        }
      }
    }
  }
}

.comment {
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  .comment-user {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    .avatar-container {
      margin-right: 8px;
    }
    .user-name {
      font-size: 12px;
      font-weight: 600;
    }
  }
  .comment-bubble {
    background-color: $white;
    border: 1px solid $grey3;
    border-radius: 0 12px 12px 12px;
    padding: 12px;
    margin-bottom: 6px;
    max-width: 60%;
    display: flex;
    align-items: flex-end;
    position: relative;
    .comment-text {
      white-space: pre-line;
      line-height: 20px;
    }
    .comment-time {
      color: $grey6;
      font-size: 12px;
      padding-left: 6px;
      line-height: 20px;
    }
    .rich-text-field {
      .control-group {
        .button-group {
          .button {
            height: 20px;
            width: 20px;
            min-width: 20px;
          }
          .button-icon {
            height: 16px;
            width: 16px;
          }
        }
      }
    }
  }
  &.self {
    align-items: flex-end;
    .comment-bubble {
      background-color: $pink2;
      border-color: $pink1;
      border-radius: 12px 12px 0 12px;
      .like-button {
        right: 100%;
        left: auto;
        transform: translateX(-8px);
      }
    }
  }
  &.editing {
    .comment-bubble {
      padding: 0 0 36px 0;
      .rich-text-field {
        border: none;
        .control-group {
          display: block;
          background-color: $white;
          border-radius: 12px 12px 0 0;
        }
      }
      .comment-time {
        display: none;
      }
      .edit-comment-actions {
        position: absolute;
        bottom: 6px;
        right: 6px;
        height: 26px;
        .btn {
          height: 26px;
          width: 26px;
          background-color: $success;
          &:not(:last-child) {
            margin-right: 12px;
          }
          &.cancel-button {
            background-color: $warning;
            .MuiButton-startIcon {
              height: 12px;
              width: 12px;
            }
          }
        }
      }
    }
  }
  .unread-separator {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 20px 0 8px;
    .separator-text {
      font-size: 12px;
      font-weight: 600;
      flex-shrink: 0;
      margin: 0 10px;
    }
    &::before,
    &::after {
      content: '';
      display: block;
      height: 1px;
      background-color: $grey3;
      width: 100%;
    }
  }
  .like-button {
    background-color: $pink2;
    border-radius: 24px;
    height: 24px;
    padding: 0;
    min-width: 24px;
    position: absolute;
    left: 100%;
    transform: translateX(8px);
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .button-content {
      display: flex;
      align-items: center;
      .svg-icon {
        height: 16px;
      }
      .count {
        color: $black;
        font-size: 12px;
        line-height: 12px;
        font-weight: 600;
        margin-left: 6px;
      }
    }
    &.liked {
      background-color: $primaryPurple;
      .button-content {
        .count {
          color: $white;
        }
      }
    }
    &.othersLiked {
      padding: 0 8px;
    }
  }
  .read-receipts {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
    width: calc(100% - 38px);
    display: flex;
    flex-wrap: wrap;
    .receipt {
      margin: 0 2px 4px;
      .avatar-container .MuiAvatar-root {
        width: 22px;
        height: 22px;
        .avatar-text {
          font-size: 12px;
        }
      }
      .avatar-container.with-prefix .avatar-text {
        font-size: 9px;
      }
    }
  }
  .comment-suggestion {
    background-color: $grey3;
    width: calc(100% + 40px);
    padding: 10px 20px;
    margin: 8px 0 0 -20px;
    box-sizing: border-box;
    .visibility-label {
      font-size: 10px;
      font-weight: 500;
      color: $grey6;
      margin-bottom: 8px;
    }
    .suggestion-logo {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 10px;
      .logo {
        height: 22px;
        margin-right: 10px;
      }
    }
    .comment-bubble {
      margin-bottom: 0;
      flex-direction: column;
      align-items: flex-start;
      max-width: 80%;
      flex-wrap: wrap;
      .comment-time {
        margin-left: auto;
      }
      .actions {
        margin-top: 14px;
        flex-basis: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .action {
          padding: 2px 8px;
          height: auto;
          font-size: 12px;
          font-weight: 600;
          &:not(:last-child) {
            margin-bottom: 14px;
          }
        }
      }
    }
  }
}
